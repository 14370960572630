import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import sideNav from "../data/documentation-nav";
import Accordion from "./accordion";
import { FaBars, FaTimes } from "react-icons/fa";

const HowToNav = () => {
  console.log("sideNav: ", sideNav);
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <div
        className="nav-icon"
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isOpen ? (
          <FaBars fontSize="1.3rem" color="#333" />
        ) : (
          <FaTimes fontSize="1.3rem" color="#333" />
        )}
      </div>
      <nav className={isOpen ? "open" : null}>
        <h4>Documentation</h4>
        {sideNav.map((item, index) => {
          return (
            <Accordion item={item} hasCaret={item.links}>
              {item.links &&
                item.links.map((link) => {
                  return (
                    <Link
                      exact
                      to={link.route}
                      className="side-nav-link"
                      activeClassName="active-side-nav-link"
                    >
                      {link.name}
                    </Link>
                  );
                })}
            </Accordion>
          );
        })}
      </nav>
    </>
  );
};

export default HowToNav;
