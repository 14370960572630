import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";

const Teams = () => (
  <HowToLayout>
    <h2>Teams</h2>
  </HowToLayout>
);

export default Teams;
