import React, { useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { navigate } from "gatsby";

const Accordion = ({ item, children, hasCaret }) => {
  const [isActive, setIsActive] = useState(true);
  return (
    <div
      className={isActive ? "accordion" : "accordion closed"}
      onClick={() => (hasCaret ? setIsActive(!isActive) : navigate(item.route))}
    >
      {hasCaret ? (
        isActive ? (
          <FaAngleDown fontSize=".8rem" color="#ccc" />
        ) : (
          <FaAngleRight fontSize=".8rem" color="#ccc" />
        )
      ) : null}
      <span style={{ marginLeft: 6 }}>{item.name}</span>
      {children}
    </div>
  );
};

export default Accordion;
