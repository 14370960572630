import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { navigate } from "gatsby";
import { document } from "browser-monads";

const TableOfContents = () => {
  const [nodes, setNodes] = useState(null);
  useEffect(() => {
    setNodes(Array.from(document.querySelectorAll("h3")));
    // console.log("nodeArray tableOfContents: ", nodeArray);
  }, []);
  console.log("nodes tableOfContents: ", nodes);
  const [isActive, setIsActive] = useState(false);
  return !nodes || nodes.length < 1 ? null : (
    <div className="table-of-contents">
      <h5>Article Contents</h5>
      {nodes.map((node) => {
        return (
          <a href={"#" + node.id} className="contents-link">
            {node.innerText}
          </a>
        );
      })}
    </div>
  );
};

export default TableOfContents;
