import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import navOptions from "../data/documentation-nav";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const DocsBottomNav = () => {
  const location = useLocation();
  console.log("Location: ", location);
  const howToIndex = navOptions.findIndex((nav) =>
    location.pathname.includes(`/how-to/${nav.path}`)
  );
  const linkIndex =
    howToIndex >= 0
      ? navOptions[howToIndex].links.findIndex(
          (item) => item.route === location.pathname
        )
      : null;
  console.log("linkIndex: ", linkIndex);
  const linkObject =
    linkIndex !== null ? navOptions[howToIndex].links[linkIndex] : null;
  console.log("linkObject: ", linkObject);
  const [backLink, setBackLink] = useState(null);
  const [nextLink, setNextLink] = useState(null);
  function FindBackLink() {
    if (linkObject !== null && howToIndex >= 0 && linkIndex > 0) {
      setBackLink(navOptions[howToIndex].links[linkIndex - 1]);
    }
  }
  function FindNextLink() {
    if (
      linkObject !== null &&
      howToIndex >= 0 &&
      linkIndex !== navOptions[howToIndex].links.length - 1
    ) {
      setNextLink(navOptions[howToIndex].links[linkIndex + 1]);
    }
  }
  useEffect(() => {
    FindBackLink();
    FindNextLink();
  }, []);
  console.log("backLink: ", backLink);
  console.log("nextLink: ", nextLink);
  return (
    <div className="docs-bottom-nav">
      {backLink !== null ? (
        <Link
          to={
            linkIndex === 1
              ? `/how-to/${navOptions[howToIndex].path}`
              : `/how-to/${
                  navOptions[howToIndex].path
                }/${backLink.route.substring(
                  backLink.route.lastIndexOf("/") + 1
                )}`
          }
          className="docs-bottom-nav-link docs-bottom-nav-link-left"
        >
          <FaArrowLeft className="docs-bottom-nav-icon" />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <span style={{ fontSize: ".6rem" }}>Previous Section</span>
            <span>{backLink.name}</span>
          </div>
        </Link>
      ) : null}
      {nextLink !== null ? (
        <Link
          className="docs-bottom-nav-link docs-bottom-nav-link-right"
          to={`/how-to/${
            navOptions[howToIndex].path
          }/${nextLink.route.substring(nextLink.route.lastIndexOf("/") + 1)}`}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: ".6rem" }}>Next Section</span>
            <span>{nextLink.name}</span>
          </div>
          <FaArrowRight className="docs-bottom-nav-icon" />
        </Link>
      ) : null}
    </div>
  );
};

export default DocsBottomNav;
