import React from "react";
import HowToNav from "./how-to-nav";
import Layout from "./layout";
import TableOfContents from "./tableOfContents";
import DocsBottomNav from "./docsBottomNav";

const HowToLayout = ({ children }) => {
  return (
    <Layout
      title="4BAGR | How To Documentation"
      hideFooter={true}
      type={"howto"}
    >
      <div className="documentation-wrap">
        <HowToNav />
        <TableOfContents />
        <main>
          {children}
          <DocsBottomNav />
        </main>
      </div>
    </Layout>
  );
};

export default HowToLayout;
